export default {
	name: "layout",
	state: {
		orderPayment: false,
		updateOrderList: false,
		payInfo: {},
		scrollOffset: 0,
		// showSignIn: true, // ===================================================================== 这里要注释，调试实名认证使用
		showSignIn: false, // 显示扫码登录 ======================================================= 这里要复原
		isCountTo: false,
		routePath: "",
		seriesDetails: {
			name: "",
			details: "",
		},
		categoryState: {
			nameZH: "",
			nameEN: "",
		},
		// isBindPhone: true, // ===================================================================== 这里要注释，调试实名认证使用
		isBindPhone: false, // 显示绑定手机号和实名认证 ======================================================= 这里要复原
		wxLoginKey: "",
		isGetApi: false, // 页面懒加载使用
		imglink: "", // 分享二维码图片链接
		showHomeTopBanner: false, // 展示首页的banner
		isShowBackTop: false, // 控制是否显示返回顶部按钮
		searchText: "",
		myProductNid: "", // 保存我的某个藏品的Nid
		showTransferProtocol: false, // 展示赠与模块的用户协议
		agreeTransferProtocol: false, // 同意赠与模块的用户协议
	},
	mutations: {
		TOGGLE_UPDATE(state) {
			state.updateOrderList = !state.updateOrderList
		},
		UPDATE_PAYINFO(state, payInfo) {
			state.payInfo = payInfo
		},
		TOGGLE_PAYMENT(state) {
			state.orderPayment = !state.orderPayment
		},
		TOGGLE_SIGNIN(state) {
			state.showSignIn = !state.showSignIn
		},
		SET_ISCOUNTTO(state, isCountTo) {
			state.isCountTo = isCountTo
		},
		SET_ROUTEPATH(state, routePath) {
			state.routePath = routePath
		},
		SET_SERIESDETAILS(state, seriesDetails) {
			state.seriesDetails = seriesDetails
		},
		SET_CATEGORYSTATE(state, categoryState) {
			state.categoryState = categoryState
		},
		SET_ISBINDPHONE(state, isBindPhone) {
			state.isBindPhone = isBindPhone
		},
		SET_WXLOGINKEY(state, wxLoginKey) {
			state.wxLoginKey = wxLoginKey
		},
		SET_ISGETAPI(state, isGetApi) {
			state.isGetApi = isGetApi
		},
		SET_IMGLINK(state, imglink) {
			state.imglink = imglink
			console.log("state.imglink", state.imglink)
		},
		SET_SHOWHOMETOPBANNER(state, showHomeTopBanner) {
			state.showHomeTopBanner = showHomeTopBanner
		},
		SET_ISSHOWBACKTOP(state, isShowBackTop) {
			state.isShowBackTop = isShowBackTop
		},
		SET_SEARCHTEXT(state, searchText) {
			state.searchText = searchText
		},
		SET_MYPRODUCTNID(state, myProductNid) {
			state.myProductNid = myProductNid
		},
		SET_SHOWTRANSFERPROTOCOL(state, showTransferProtocol) {
			state.showTransferProtocol = showTransferProtocol
		},
		SET_AGREETRANSFERPROTOCOL(state, agreeTransferProtocol) {
			state.agreeTransferProtocol = agreeTransferProtocol
		},
	},
	actions: {},
}
