export default {
  name: 'chain',
  state: {
    chainInfo: {
      type: 'product',
      created: true,
      name: '英雄手册.三英战吕布',
      hash: 'hash hash',
      productCode: '123',
      author: '王大拿',
      press: '王大拿公司',
      router: [
        {
          location: '123123123123123123123123123123123123123123123123123123123123123123123123',
          type: '藏品发放',
          time: '2018-01-01 12:00:00',
          hash: 'hash hash',
        },
        {
          location: '123123123123123123',
          type: '藏品发放',
          time: '2018-01-01 12:00:00',
          hash: 'hash hash',
        }
      ]
    }
  },
  mutations: {},
  actions: {},
}

/*
{
  type: 'person',
  code: '00000000000',
  createTime: '2021-10-10 12:00:11',
  created: true,
  productList: [
    {
      name: '123',
      hash: 'hash hash',
      collectTime: '2018-01-01 12:00:00',
    },
    {
      name: '123',
      hash: 'hash hash',
      collectTime: '2018-01-01 12:00:00',
    },
    {
      name: '123',
      hash: 'hash hash',
      collectTime: '2018-01-01 12:00:00',
    },
    {
      name: '123',
      hash: 'hash hash',
      collectTime: '2018-01-01 12:00:00',
    }
  ]
}

{
  type: 'product',
  created: true,
  name: '英雄手册.三英战吕布',
  hash: 'hash hash',
  productCode: '123',
  author: '王大拿',
  press: '王大拿公司',
  router: [
    {
      location: '123123123123123123',
      type: '藏品发放',
      time: '2018-01-01 12:00:00',
      hash: 'hash hash',
    },
    {
      location: '123123123123123123',
      type: '藏品发放',
      time: '2018-01-01 12:00:00',
      hash: 'hash hash',
    }
  ]
}
*/