import axios from "axios"
import { idcard, checkName } from './check'
import router from '@/router'
import { Toast } from "vant"

export const request = (params) => {
  return new Promise((resolve, reject) => {
    axios(params).then(res => {
      if (res.data.Data === 'overdue') {
        router.replace('/reg')
      }
      if (!res.data.IsSuccess && res.data.Data && res.data.Data.state === -1) {
        router.replace('/ver')
      }

      resolve(res)

    }).catch(err => {
      // Toast.fail('活动太火爆，请稍后再试')
      reject(err)
    })
  })
}

export default {
  request,
  idcard,
  checkName
}