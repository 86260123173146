import { createApp } from 'vue';
import Router from '@/router';
import Store from '@/store';

import App from '@/App.vue';
import IconFont from '@/components/IconFont.vue';
import SvgIcon from '@/components/SvgIcon.vue';

import 'element-plus/dist/index.css'

import { ElCascader } from 'element-plus'


import i18n from '@/locales';

import 'virtual:svg-icons-register';

import { Icon, ConfigProvider, Swipe, SwipeItem, PullRefresh, List, Button, Empty, Dialog, Tab, Tabs, Step, Steps, Area, Popup, Sticky, Loading, Picker } from 'vant';

import 'vant/lib/index.css';
import '@/common.scss';

const app = createApp(App);

const vantComponents = [Icon, ConfigProvider, Swipe, SwipeItem, PullRefresh, List, Button, Empty, Dialog, Tab, Tabs, Step, Steps, Area, Popup, Sticky, Loading, Picker];

for (let component of vantComponents) {
	app.use(component);
}

app.use(ElCascader)

app.component('IconFont', IconFont)
app.component('SvgIcon', SvgIcon)

app.use(i18n).use(Router).use(Store).mount('#app');

// 判断是否是微信环境
Store.commit('SET_WX_ENV', /MicroMessenger/i.test(window.navigator.userAgent));
