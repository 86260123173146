<template>
  <div class="splash">
    <img class="image" :src="image" />
    <div class="info">
      <!-- <div class="count">{{ state.duration }}秒</div> -->
      <div class="skip" @click="emit('skip')">跳过 {{ state.duration }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'

export default {
  name: 'Splash'
}
</script>

<script setup>
import { reactive } from 'vue'

const state = reactive({
  duration: 5
})

const props = defineProps({
  image: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  finish: null,
  skip: null
})

const countDown = () => {
  const timer = setTimeout(() => {
    state.duration--
    countDown()
    if (state.duration < 0) {
      clearTimeout(timer)
      emit('finish')
    }
  }, 1000)
}


onMounted(() => {
  countDown()
})

</script>

<style scoped lang="scss">
.splash {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 100;

  .image {
    width: 100%;
    height: 100%;
    // object-fit: contain;
    object-fit: cover;
  }

  .info {
    width: 100%;
    position: absolute;
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 50px;

    .skip {
      padding: 0 40px;
      color: #fff;
      height: 60px;
      font-size: 26px;
      background-color: rgba($color: #000000, $alpha: .4);
      margin-left: 20px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        opacity: .6;
      }
    }

    .count {
      color: #fff;
      font-size: 28px;
    }
  }
}
</style>