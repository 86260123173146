<template>
  <span class="iconfont" :class="iconClass" :style="{ color: color }"></span>
</template>

<script>
export default {
  name: 'icon-font',
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import '../icons/iconfont.css'

const store = useStore()

const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  size: {
    type: Number,
    default: 32,
  },
  bold: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: '#333',
  }
})

const pc = computed(() => store.state.isMobile)
const iconClass = computed(() => props.icon ? `i-${props.icon}` : '')
const fontSize = computed(() => pc.value ? `${props.size / 7.5}vw` : `${props.size}px`)
const fontWeight = computed(() => props.bold ? 'bold' : 'normal')

</script>

<style scoped lang="scss">
.iconfont {
  font-size: v-bind(fontSize);
  font-weight: v-bind(fontWeight);
}
</style>