import { request } from '@/utils'
import axios from 'axios'

export const getBanner = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getBanner&${param}`
  })
}

export const getQNA = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getCommonProblemList&${param}`
  })
}

export const getTypes = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTType&${param}`
  })
}

export const getIndexList = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTorseriesbyTidnew&${param}`
  })
}

export const getSerialDetail = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTseriesinfo&${param}`
  })
}

export const getSerialProducts = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTbySid&${param}`
  })
}

export const getProductDetail = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTinfo&${param}`
  })
}

export const uploadFile = (formData) => axios({
  headers: {
    'Content-Type': 'multipart/form-data;charset=UTF-8'
  },
  url: '/api/Server.aspx?action=uploadpic',
  method: 'post',
  data: formData
})

export const getNFTInfo = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTinfobyNFTid&${param}`
  })
}

export const getPersonData = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTlistbyuseraddress&${param}`
  })
}

export const getNFTTime = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getmyorderbyNFTID&${param}`
  })
}

export const checkExchangeCode = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=CheackExchangeCode&${param}`
  })
}

export const exchangeProduct = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=exchangeNft&${param}`
  })
}

export const getPro = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getproTxt&${param}`
  })
}

export const getAllSeries = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getNFTseriesbyTid&${param}`
  })
}

export const getCompanyInfo = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getCompanyInfo&${param}`
  })
}

export const getPCLoginQRcode = (delay = 1000) => {
  return request({
    url: `/api/Server.aspx?action=getlogincode`
  })
}

export const getLoginStatus = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getloginfobykey&${param}`
  })
}

export const getToplist = (data = {}) => {
  const param = Object.keys(data).map(key => `${key}=${data[key]}`).join('&')
  return request({
    url: `/api/Server.aspx?action=getrankNFT&${param}`
  })
}



export const getRankList = () => {
  return request({
    url: `/api/Server.aspx?action=getrankNFT`
  })
}

export default {
  getBanner,
  getQNA,
  getTypes,
  getIndexList,
  getSerialDetail,
  getAllSeries,
  getCompanyInfo,
  getPCLoginQRcode,
  getLoginStatus,
  getToplist
}