<template>
  <svg class="icon" aria-hidden="true" :style="{ width: `${realSize}`, height: `${realSize}` }">
    <use :xlink:href="`#i-${icon}`" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'


const store = useStore()

const props = defineProps({
  icon: {
    type: String,
    required: true
  },
  color: {
    type: String,
    default: '#fff'
  },
  size: {
    type: Number,
    default: 32
  }
})

const realSize = computed(() => store.state.isMobile ? `${props.size / 750 * 100}vw` : `${props.size}px`)

</script>