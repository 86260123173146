import { createI18n } from 'vue-i18n'

const messages = {
  zh: {
    lang: 'En',
    types: {
      all: '全部',
      fest: '端午',
      art: '艺术',
      cultural: '非遗',
      music: '音乐'
    },
    tabs: {
      index: '数字藏品',
      digit: '数藏库',
      rank: '排行榜',
      mine: '我的'
    }
  },
  en: {
    lang: '中',
    types: {
      all: 'All',
      fest: 'Fest',
      art: 'Art',
      cultural: 'cultural',
      music: 'Music'
    },
    tabs: {
      index: 'Index',
      digit: 'Digit',
      rank: 'Rank',
      mine: 'Mine'
    }
  }
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  global: true,
  locale: 'zh',
  fallbackLocale: 'zh',
  messages
})