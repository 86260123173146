export default {
  name: 'user',
  state: {
    userInfo: {},
    address: {},
    orderInfo: {},
    spot: false
  },
  mutations: {
    UPDATE_TEL(state, tel) {
      state.userInfo.mobile = tel
    },
    UPDATE_ORDER_INFO(state, info) {
      state.orderInfo = info
    },
    UPDATE_USER_INFO: (state, userInfo) => {
      // console.log('set')
      // console.log(userInfo)
      window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
      state.userInfo = userInfo
    },
    UPDATE_ADDRESS: (state, address) => {
      state.address = address
    },
    CLEAR_ADDRESS: (state) => {
      state.address = {}
    },
    UPDATE_SPOT: (state, spot) => {
      state.spot = spot
    }
  },
  actions: {},
}