export default {
  name: 'tabs',
  state: {
    current: 0
  },
  mutations: {
    SET_CURRENT_TAB(state, current) {
      state.current = current
    }
  },
  actions: {},
}