export default {
  name: 'veri',
  state: {
    phoneOrveri: false, // false 代表目前是在绑定手机，true 代表目前在进行实名认证
    phoneIsDone: false, // 手机验证是否完成
    isGetPhoneCode: false, // 是否获取验证码
    veriIsDone: false, // 实名认证是否完成
  },
  mutations: {
    SET_PHONEORVERI(state, value) {
      state.phoneOrveri = value
    },
    SET_PHONEISDONE(state, value) {
      state.phoneIsDone = value
    },
    SET_VERIISDONE(state, value) {
      state.veriIsDone = value
    },
    SET_ISGETPHONECODE(state, value) {
      state.isGetPhoneCode = value
    }
  },
  actions: {},
}
