import { createStore } from 'vuex'

const importModules = import.meta.globEager('./modules/*.js')

const modules = Object.keys(importModules).reduce((modules, key) => {
  const module = importModules[key].default
  modules[module.name] = module
  return modules
}, {})

export default createStore({
  modules,
  state: {
    lang: 'zh',
    searchHistory: [],
    searchList: [],
    isMobile: false,
    isWX: false,
    homeScrollTop: 0,
    renderer: null
  },
  getters: {
    lang: state => state.lang,
  },
  mutations: {
    SET_RENDERER(state, renderer) {
      state.renderer = renderer
    },
    SET_SCROLL_TOP(state, top) {
      state.homeScrollTop = top
    },
    SET_WX_ENV: (state, isWX) => {
      state.isWX = isWX
    },
    SET_ENV(state, isMobile) {
      state.isMobile = isMobile
    },
    SWITCH_LANG(state, lang) {
      state.lang = lang
    },
    SET_SEARCH_HISTORY(state, history) {
      localStorage.setItem('searchHistory', JSON.stringify(history))
      state.searchHistory = history
    },
    SET_SEARCH_LIST(state, list) {
      state.searchList = list
    },
    DEL_SEARCH_HISTORY(state) {
      localStorage.removeItem('searchHistory')
      state.searchHistory = []
    }
  },
  actions: {},
})