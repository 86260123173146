import { createRouter, createWebHashHistory } from "vue-router"
import store from "../store"

const isMobile =
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  )

store.commit("SET_ENV", isMobile)
// console.log(isMobile)

const devicePath = isMobile ? "/mobile" : "/pc"

const views = import.meta.glob("../views/**/*.vue")
// console.log(views)

const routes = [
  {
    path: "/three_test",
    name: "ThreeTest",
    component: views[`../views${devicePath}/ThreeTest.vue`],
    meta: {
      title: "测试",
    },
  },
  {
    path: "/",
    name: "tab-page",
    // component: views[devicePath ? `../views${devicePath}/TabPage.vue` : '../views/Home.vue'],
    component: views[`../views${devicePath}/TabPage.vue`],
    children: [
      {
        path: "",
        name: "home",
        component: views[`../views${devicePath}/Home.vue`],
        meta: {
          title: "星原数艺",
          tabs: true,
          index: 0,
          keepAlive: true,
        },
      },
      {
        path: "digit",
        name: "digit",
        component: views[`../views${devicePath}/Digit.vue`],
        meta: {
          title: "数藏库",
          tabs: true,
          index: 1,
        },
      },
      {
        path: "pc/myproduct/:params",
        name: "pc-myproduct",
        component: views[`../views${devicePath}/MyProduct.vue`],
        meta: {
          title: "藏品详情",
        },
      },
      {
        path: "rank",
        name: "rank",
        component: views[`../views${devicePath}/Rank.vue`],
        meta: {
          title: "排行榜",
          tabs: true,
          index: 2,
        },
        children: [],
      },
      {
        path: "mine",
        name: "mine",
        component: views[isMobile ? `../views${devicePath}/Mine.vue` : `../views${devicePath}/Mine/Index.vue`],
        meta: {
          title: "我的",
          tabs: true,
          index: 3,
        },
        children: isMobile
          ? []
          : [
            {
              path: "order_list",
              name: "OrderList",
              component: views[`../views${devicePath}/Mine/OrderList.vue`],
              meta: {
                title: "我的订单",
              },
            },
            {
              path: "rights",
              name: "Rights",
              component: views[`../views${devicePath}/Mine/Rights.vue`],
              meta: {
                title: "我的权益",
              },
            },
            {
              path: "service",
              name: "Service",
              component: views[`../views${devicePath}/Mine/Service.vue`],
              meta: {
                title: "我的客服",
              },
            },
            {
              path: "order_detail/:oid",
              name: "OrderDetail",
              component: views[`../views${devicePath}/Mine/OrderDetail.vue`],
              meta: {
                title: "订单详情",
              },
            },
            {
              path: "collection",
              name: "MyCollection",
              component: views[`../views${devicePath}/Mine/MyCollection.vue`],
              meta: {
                title: "我的收藏",
              },
            },
            {
              path: "user_config",
              name: "UserConfig",
              component: views[`../views${devicePath}/Mine/UserConfig.vue`],
              meta: {
                title: "账户设置",
              },
            },
            {
              path: "locations",
              name: "Locations",
              component: views[`../views${devicePath}/Mine/Locations.vue`],
              meta: {
                title: "地址管理",
              },
            },
            {
              path: "exchange",
              name: "Exchange",
              component: views[`../views${devicePath}/Mine/Exchange.vue`],
              meta: {
                title: "兑换礼品",
              },
            },
            {
              path: "transfer",
              name: "Transfer",
              component: views[`../views${devicePath}/Mine/Transfer.vue`],
              meta: {
                title: "转赠藏品",
              },
            },
            {
              path: "password",
              name: "password",
              component: views[`../views${devicePath}/Mine/Password.vue`],
              meta: {
                title: "操作密码",
              },
            },
          ],
      },
      {
        path: "pc/series/:id/:nameZH/:nameEN",
        name: "pc-series",
        component: views[`../views${devicePath}/Series.vue`],
        meta: {
          title: "系列",
        },
      },
      {
        path: "pc/serieslist/:id",
        name: "pc-serieslist",
        component: views[`../views${devicePath}/Series.vue`],
        meta: {
          title: "系列藏品",
        },
      },
      {
        path: "pc/detail/:id",
        name: "pc-detail",
        component: views[`../views${devicePath}/Detail.vue`],
        meta: {
          title: "藏品详情",
        },
      },
      {
        path: "pc/exchange/:id/:code",
        name: "PCExchange",
        component: views[`../views${devicePath}/Exchange.vue`],
        meta: {
          title: "藏品兑换",
        },
      },
      {
        path: "pc/order/:type/:id",
        name: "pc-order",
        component: views[`../views${devicePath}/Order.vue`],
        meta: {
          title: "订单详情",
        },
      },
      {
        path: "pc/qna",
        name: "pc-qna",
        component: views[`../views${devicePath}/Qna.vue`],
        meta: {
          title: "常见问题",
        },
      },
      {
        path: "pc/userAgreement",
        name: "pc-userAgreement",
        component: views[`../views${devicePath}/Qna.vue`],
        meta: {
          title: "用户协议",
        },
      },
      {
        path: "pc/privacyPolicy",
        name: "pc-privacyPolicy",
        component: views[`../views${devicePath}/Qna.vue`],
        meta: {
          title: "隐私协议",
        },
      },
      {
        path: "pc/recruit",
        name: "pc-recruit",
        component: views[`../views${devicePath}/Qna.vue`],
        meta: {
          title: "招募",
        },
      },
      {
        path: "pc/searchlist/:text",
        name: "pc-searchlist",
        component: views[`../views${devicePath}/Search.vue`],
        meta: {
          title: "搜索结果",
        },
      },
      {
        // path: 'pc/show/:mod/:isBuy/:exr/:Nid',
        path: "pc/show/:params",
        name: "pc-show",
        component: views[`../views${devicePath}/Show.vue`],
        meta: {
          title: "3D展示",
        },
      },
      {
        path: "pc/toplist",
        name: "pc-toplist",
        component: views[`../views${devicePath}/TopList.vue`],
        meta: {
          title: "排行榜",
        },
      },
      // pc 转增模块
      {
        path: "pc/transfer/:NFTId",
        name: "pc-transfer",
        component: views[`../views${devicePath}/Transfer/Index.vue`],
        meta: {
          title: "确认赠与",
        },
      },
      // pc 接收转赠模块
      {
        path: "pc/agreeTransfer/:NFTid/:FromUid/:Id",
        name: "pc-agree-transfer",
        component: views[`../views${devicePath}/Transfer/Accept.vue`],
        meta: {
          title: "接收赠与",
        },
      },
    ],
    meta: {
      title: "星原数艺",
      // keepAlive: true,
    },
  },
  {
    path: "/detail/:id",
    name: "detail",
    component: views[`../views${devicePath}/Detail.vue`],
    meta: {
      title: "藏品详情",
    },
  },
  {
    path: "/my_detail/:type/:id/:nid",
    name: "my-detail",
    component: views[`../views${devicePath}/MyDetail.vue`],
    meta: {
      title: "我的藏品",
    },
  },
  {
    path: "/operation/:id",
    name: "operation",
    component: views[`../views${devicePath}/Operation.vue`],
    meta: {
      title: "藏品互动",
    },
  },
  {
    path: "/art_detail/:sid",
    name: "art-detail",
    component: views[`../views${devicePath}/ArtDetail.vue`],
    meta: {
      title: "艺术详情",
    },
  },
  {
    path: "/order/:type/:id",
    name: "order",
    component: views[`../views${devicePath}/Order.vue`],
    meta: {
      title: "确认订单",
    },
  },
  {
    path: "/qna",
    name: "qna",
    component: views[`../views${devicePath}/QNA.vue`],
    meta: {
      title: "常见问题",
    },
  },
  {
    path: "/reg",
    name: "register",
    component: views[`../views${devicePath}/Reg.vue`],
    meta: {
      title: "注册",
    },
  },
  {
    path: "/ver",
    name: "verify",
    component: views[`../views${devicePath}/Verify.vue`],
    meta: {
      title: "实名认证",
    },
  },
  {
    path: "/fav",
    name: "favorite",
    component: views[`../views${devicePath}/Favorite.vue`],
    meta: {
      title: "我的收藏",
    },
  },
  {
    path: "/rights",
    name: "rights",
    component: views[`../views${devicePath}/Rights.vue`],
    meta: {
      title: "我的权益",
    },
  },
  {
    path: "/order_list",
    name: "order-list",
    component: views[`../views${devicePath}/Order/List.vue`],
    meta: {
      title: "我的订单",
    },
  },
  {
    path: "/order_detail/:id",
    name: "order-detail",
    component: views[`../views${devicePath}/Order/Detail.vue`],
    meta: {
      title: "订单详情",
    },
  },
  {
    path: "/exchange/:code/:id",
    name: "exchange",
    component: views[`../views${devicePath}/Exchange.vue`],
    meta: {
      title: "兑换",
    },
  },
  {
    path: "/chain_search",
    name: "chain-search",
    component: views[`../views${devicePath}/Chain/Search.vue`],
    meta: {
      title: "区块链信息查询",
    },
  },
  {
    path: "/chain_info/:code",
    name: "chain-info",
    component: views[`../views${devicePath}/Chain/Info.vue`],
    meta: {
      title: "区块链查证信息概览",
    },
  },
  {
    path: "/user_config",
    name: "user-config",
    component: views[`../views${devicePath}/UserConfig.vue`],
    meta: {
      title: "账户设置",
    },
  },
  {
    path: "/addr_list",
    name: "address-list",
    component: views[`../views${devicePath}/Address/List.vue`],
    meta: {
      title: "我的地址",
    },
  },
  {
    path: "/addr_edit/:type/:id",
    name: "address-edit",
    component: views[`../views${devicePath}/Address/Edit.vue`],
    meta: {},
  },
  {
    path: "/search_list/:text",
    name: "search-list",
    component: views[`../views${devicePath}/SearchList.vue`],
    meta: {
      title: "搜索结果",
    },
  },
  {
    path: "/protocol/:type",
    name: "protocol",
    component: views[`../views${devicePath}/Protocol.vue`],
    meta: {},
  },
  {
    path: "/transfer_detail/:id/:nid",
    name: "transfer-detail",
    component: views[`../views${devicePath}/Transfer/Detail.vue`],
    meta: {
      title: "确认赠与",
    },
  },
  {
    path: "/transfer_confirm/:nftid",
    name: "transfer-confirm",
    component: views[`../views${devicePath}/Transfer/Confirm.vue`],
    meta: {
      title: "确认赠与",
    },
  },
  {
    path: "/transfer_list",
    name: "transfer-list",
    component: views[`../views${devicePath}/Transfer/List.vue`],
    meta: {
      title: "转赠藏品",
    },
  },
  {
    path: "/transfer_accept/:id",
    name: "transfer-accept",
    component: views[`../views${devicePath}/Transfer/Accept.vue`],
    meta: {
      title: "接收转赠藏品",
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (isMobile) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    } else {
      store.commit("SET_ISSHOWBACKTOP", false)
    }
  },
  routes,
})

// console.log(routes)

router.beforeEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = "星原数艺"
  }

  if (to.meta.tabs) {
    store.commit("SET_CURRENT_TAB", to.meta.index)
  }

  if (!isMobile) {
    store.commit("SET_ROUTEPATH", to.path)
  }
})

export default router