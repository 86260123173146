<template>
  <!-- <router-view /> -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
  </router-view>
  <!-- 广告 -->
  <splash :image="state.splash" v-if="state.splash" @finish="state.splash = ''" @skip="state.splash = ''" />
</template>

<script setup>
import axios from 'axios';
import { useStore } from 'vuex';
import { onMounted, reactive } from 'vue'
import splash from '@/components/Splash.vue'
import { getBanner } from '@/api/public'

const store = useStore()

const searchHistory = localStorage.getItem('searchHistory') ? JSON.parse(localStorage.getItem('searchHistory')) : []

store.commit('SET_SEARCH_HISTORY', searchHistory)

const state = reactive({
  splash: ''
})

if (/MicroMessenger/i.test(window.navigator.userAgent)) {
  if (window.location.href.indexOf('index.html') !== -1 && import.meta.env.MODE !== "development") {
    window.location.href = window.location.href.replace('index.html', 'index.aspx')
  }
  const host = window.location.host
  axios({
    url: `/api/getTicket.aspx?url=${encodeURIComponent(window.location.href)}`,
  }).then(res => {
    // console.log(res)
    const { appid, noncestr, sign, timestamp } = res.data
    wx.config({
      debug: false,
      appId: appid,
      timestamp: `${timestamp}`,
      nonceStr: noncestr,
      signature: sign,
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'onVoicePlayEnd',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'translateVoice',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard',
      ],
      openTagList: ['wx-open-subscribe']
    })

    wx.error(function (err) {
      console.log('wx.error' + err)
    })

    wx.ready(() => {
      // 获取当前域名 
      wx.updateAppMessageShareData({
        title: '星原数艺',
        desc: '以文化引领元宇宙世界！',
        link: `https://${host}/`,
        imgUrl: `https://${host}/images/share.jpg`,
        success: function () {
          // 设置成功
        }
      })

      wx.updateTimelineShareData({
        title: '星原数艺',
        link: `https://${host}/`,
        imgUrl: `https://${host}/images/share.jpg`,
        success: function () {
          // 设置成功
        }
      })

      //     wx.onMenuShareTimeline({
      //       title: '星原数艺', // 分享标题
      //       link: `https://${host}/`,
      //       imgUrl: `https://${host}/images/logo.png`,
      //       success: function () {
      //         // 用户点击了分享后执行的回调函数
      //         console.log('分享成功')
      //       }
      //     })
      //   })

      //   wx.onMenuShareAppMessage({
      //     title: '星原数艺', // 分享标题
      //     desc: '点击开启你的元宇宙世界！', // 分享描述
      //     link: `https://${host}/`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
      //     imgUrl: `https://${host}/images/logo.png`, // 分享图标
      //     success: function () {
      //       // 用户点击了分享后执行的回调函数
      //       console.log('分享成功')
      //     }
    });

  })
}

axios({
  url: `/api/Server.aspx?action=getLogininfo`,
  method: 'post',
}).then(res => {
  const { data } = res
  if (data.IsSuccess) {
    store.commit('UPDATE_USER_INFO', data.Data)
  }
  // else {
  //   Toast.fail(data.Msg)
  // }
})

getBanner({
  type: 1,
  DataType: store.state.isMobile ? 2 : 1,
  language: store.state.lang
}).then(res => {
  // console.log(res)
  const { data } = res
  if (data.IsSuccess) {
    if (data.Data.length) {
      if (window.location.href.split('#')[1] === '/') {
        state.splash = data.Data[0].ImgUrl
      }
    }
  }
})


onMounted(() => {
  // console.log('app mounted')
  // console.log(window.localStorage.getItem('userInfo'))
})
</script>

<style lang="scss">
[v-cloak] {
  opacity: 0;
}

input {
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
}
</style>
